<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import toast from 'svelte-french-toast';
    import axios from 'axios';
    import { Alert, AlertDescription } from '$lib/components/ui/alert';
    import { Button } from '$lib/components/ui/button';
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label';
    import { Switch } from '$lib/components/ui/switch';
    import { Calendar } from '$lib/components/ui/calendar';
    import * as Popover from '$lib/components/ui/popover';
    import { CalendarIcon } from 'lucide-svelte';
    import { cn } from '$lib/utils';
    import { CalendarDate, getLocalTimeZone, parseDate } from '@internationalized/date';
    import type { SettingsContext } from '@/interfaces/settings';

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser } = settingsContext;

    let successMessage = '';
    let errorMessage = '';
    let isLoading = false;
    let isPaidProfile = authUser.paid_profile === '1';
    let isOffer = !!authUser.offer?.expires_at;
    let offerDate: CalendarDate | undefined = authUser.offer?.expires_at
        ? parseDate(authUser.offer.expires_at.format('Y-m-d'))
        : undefined;

    const updateRates = async (event: Event) => {
        event.preventDefault();
        isLoading = true;
        const form = event.target as HTMLFormElement;
        const formData = new FormData(form);

        const dataToSend = {
            paid_profile: isPaidProfile ? '1' : '0',
            profile_access_price: formData.get('profile_access_price'),
            profile_access_price_3_months: formData.get('profile_access_price_3_months'),
            profile_access_price_6_months: formData.get('profile_access_price_6_months'),
            profile_access_price_12_months: formData.get('profile_access_price_12_months'),
            is_offer: isOffer,
            profile_access_offer_date: offerDate ? offerDate.toString() : null,
        };

        try {
            const response = await axios.post('/my/settings/rates/save', dataToSend, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
                },
            });
            successMessage = response.data.success;
            errorMessage = '';
            toast.success(response.data.success);

            // Update authUser with the new data
            Object.assign(authUser, {
                paid_profile: dataToSend.paid_profile,
                profile_access_price: dataToSend.profile_access_price,
                profile_access_price_3_months: dataToSend.profile_access_price_3_months,
                profile_access_price_6_months: dataToSend.profile_access_price_6_months,
                profile_access_price_12_months: dataToSend.profile_access_price_12_months,
                offer: isOffer ? { expires_at: { format: () => offerDate?.toString() || '' } } : undefined,
            });
        } catch (error: any) {
            successMessage = '';
            if (error.response && error.response.status === 422) {
                errorMessage = error.response.data.message;
            } else {
                errorMessage = 'An unexpected error occurred. Please try again.';
            }
            toast.error(errorMessage);
        } finally {
            isLoading = false;
        }
    };

    const formatDate = (date: CalendarDate | undefined): string => {
        if (!date) return 'Pick a date';
        return date.toString();
    };

    const priceInputs = [
        {
            label: 'Your default subscription price',
            name: 'profile_access_price',
            value: authUser.profile_access_price,
        },
        {
            label: '3 months subscription price',
            name: 'profile_access_price_3_months',
            value: authUser.profile_access_price_3_months,
        },
        {
            label: '6 months subscription price',
            name: 'profile_access_price_6_months',
            value: authUser.profile_access_price_6_months,
        },
        {
            label: '12 months subscription price',
            name: 'profile_access_price_12_months',
            value: authUser.profile_access_price_12_months,
        },
    ];

    // Reactive statement to update isOffer when isPaidProfile changes
    $: if (!isPaidProfile) {
        isOffer = false;
        offerDate = undefined;
    }

    onMount(() => {
        // Ensure the initial state is correctly set based on authUser data
        isPaidProfile = authUser.paid_profile === '1';
        isOffer = !!authUser.offer?.expires_at;
        offerDate = authUser.offer?.expires_at ? parseDate(authUser.offer.expires_at.format('Y-m-d')) : undefined;
    });
</script>

<form on:submit="{updateRates}" class="space-y-6">
    {#if successMessage}
        <Alert variant="success">
            <AlertDescription>{successMessage}</AlertDescription>
        </Alert>
    {/if}

    {#if errorMessage}
        <Alert variant="destructive">
            <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
    {/if}

    <div class="flex items-center space-x-2">
        <Switch id="paid-profile" bind:checked="{isPaidProfile}" />
        <Label for="paid-profile" class="text-lg m-0 ml-2">Enable subscriptions on your profile</Label>
    </div>

    {#if isPaidProfile}
        <div class="space-y-4">
            {#each priceInputs as { label, name, value }}
                <div class="space-y-2">
                    <Label for="{name}">{label}</Label>
                    <Input id="{name}" {name} type="number" step="0.01" min="0" {value} required />
                </div>
            {/each}

            <div class="space-y-2">
                <Label>Offer Settings</Label>
                <div class="flex items-center space-x-2">
                    <Switch id="is-offer" bind:checked="{isOffer}" />
                    <Label for="is-offer">Enable offer</Label>
                </div>
                {#if isOffer}
                    <Popover.Root>
                        <Popover.Trigger asChild let:builder>
                            <Button
                                variant="outline"
                                class="{cn(
                                    'w-full justify-start text-left font-normal',
                                    !offerDate && 'text-muted-foreground'
                                )}"
                                builders="{[builder]}"
                            >
                                <CalendarIcon class="mr-2 h-4 w-4" />
                                {formatDate(offerDate)}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content class="w-auto p-0">
                            <Calendar bind:value="{offerDate}" />
                        </Popover.Content>
                    </Popover.Root>
                    <p class="mt-1 text-sm text-muted-foreground">
                        To start a promotion, reduce your monthly prices and select a future promotion end date.
                    </p>
                {/if}
            </div>
        </div>
        <Button type="submit" class="w-full text-white" disabled="{isLoading}">
            {#if isLoading}
                <span class="mr-2">Saving</span>
                <span class="animate-spin">&#9696;</span>
            {:else}
                Save
            {/if}
        </Button>
    {/if}
</form>
