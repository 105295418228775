<script lang="ts">
    import { inertia, Link } from '@inertiajs/svelte';
    import * as Table from '$lib/components/ui/table';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import * as AlertDialog from '$lib/components/ui/alert-dialog/index.js';
    import { Button } from '$lib/components/ui/button/index.js';
    import TrashIcon from '@/Icons/TrashIcon.svelte';
    import EllipsisHorizontalIcon from '@/Icons/EllipsisHorizontalIcon.svelte';
    import type { SettingsData } from '@/interfaces/settings';

    export let data: SettingsData;

    console.log(data);

    function getBaseUrl(): string {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);

    function getStatusBadge(status: string): string {
        const baseClasses = 'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset';
        switch (status) {
            case 'canceled':
                return `${baseClasses} bg-yellow-50 text-yellow-800 ring-yellow-600/20`;
            case 'completed':
                return `${baseClasses} bg-green-50 text-green-700 ring-green-600/20`;
            case 'failed':
                return `${baseClasses} bg-red-50 text-red-700 ring-red-600/10`;
            default:
                return `${baseClasses} bg-gray-50 text-gray-600 ring-gray-500/10`;
        }
    }

    let subscriptionToCancel: number | null = null;
    let isDialogOpen = false;
    let isCancelling = false;
    let cancellationError: string | null = null;

    function openCancelDialog(subscriptionId: number) {
        subscriptionToCancel = subscriptionId;
        isDialogOpen = true;
        cancellationError = null;
    }

    async function cancelSubscription() {
        if (subscriptionToCancel !== null) {
            isCancelling = true;
            cancellationError = null;
            const url = `${getBaseUrl()}/subscriptions/${subscriptionToCancel}/cancel/${data.activeSubsTab}`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    redirect: 'follow',
                });

                if (response.ok) {
                    goto(response.url, { replaceState: true });
                    isDialogOpen = false;
                } else {
                    throw new Error('Failed to cancel subscription');
                }
            } catch (err) {
                const error = err as Error;
                console.error('Error cancelling subscription:', error);
                cancellationError = error.message || 'An error occurred while cancelling the subscription';
            } finally {
                isCancelling = false;
            }
        }
    }
</script>

<div class="inline-border-tabs mb-1 mt-0">
    <nav class="nav nav-pills nav-justified">
        {#each ['Subscriptions', 'Subscribers'] as tab}
            <a
                class="nav-item nav-link"
                use:inertia
                href="{getBaseUrl() +
                    (tab.toLowerCase() === 'subscriptions'
                        ? '/my/settings/subscriptions?active=Subscriptions'
                        : '/my/settings/subscriptions?active=Subscribers')}"
                class:active="{data.activeSubsTab === tab}"
            >
                <div class="d-flex align-items-center justify-content-center">
                    {tab}
                </div>
            </a>
        {/each}
    </nav>
</div>

{#if data.subscriptions.data.length > 0}
    <Table.Root>
        <Table.Header>
            <Table.Row>
                <Table.Head class="w-1/4">{data.activeSubsTab === 'subscriptions' ? 'To' : 'From'}</Table.Head>
                <Table.Head class="w-1/6">Status</Table.Head>
                <Table.Head class="hidden w-1/6 md:table-cell">Paid with</Table.Head>
                <Table.Head class="w-1/6">Renews</Table.Head>
                <Table.Head class="hidden w-1/6 md:table-cell">Expires at</Table.Head>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {#each data.subscriptions.data as subscription}
                <Table.Row>
                    <Table.Cell class="flex items-center">
                        <div class="mr-2 h-8 w-8">
                            <img
                                src="{data.activeSubsTab === 'subscriptions'
                                    ? subscription.creator.avatar
                                    : subscription.subscriber?.avatar}"
                                alt="{data.activeSubsTab === 'subscriptions'
                                    ? subscription.creator.name
                                    : subscription.subscriber?.name}"
                                class="h-8 w-8 rounded-full"
                            />
                        </div>
                        <Link
                            href="{getBaseUrl()}/profile/{data.activeSubsTab === 'subscriptions'
                                ? subscription.creator.username
                                : subscription.subscriber?.username}"
                        >
                            {data.activeSubsTab === 'subscriptions'
                                ? subscription.creator.name
                                : subscription.subscriber?.name}
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        <span class="{getStatusBadge(subscription.status)}">
                            {subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}
                        </span>
                    </Table.Cell>
                    <Table.Cell class="hidden md:table-cell">
                        {subscription.provider.charAt(0).toUpperCase() + subscription.provider.slice(1)}
                    </Table.Cell>
                    <Table.Cell>
                        {subscription.expires_at
                            ? subscription.status === data.CANCELED_STATUS
                                ? '-'
                                : dateFormatter.format(new Date(subscription.expires_at))
                            : '-'}
                    </Table.Cell>
                    <Table.Cell class="hidden md:table-cell">
                        {subscription.expires_at
                            ? subscription.status === data.ACTIVE_STATUS
                                ? '-'
                                : dateFormatter.format(new Date(subscription.expires_at))
                            : '-'}
                    </Table.Cell>
                    <div>
                        {#if subscription.status === data.ACTIVE_STATUS}
                            <DropdownMenu.Root>
                                <DropdownMenu.Trigger class="rounded-md border p-0 px-1">
                                    <EllipsisHorizontalIcon />
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content>
                                    <DropdownMenu.Item on:click="{() => openCancelDialog(subscription.id)}">
                                        <TrashIcon size="size-5" />
                                        <span class="ml-2">Cancel subscription</span>
                                    </DropdownMenu.Item>
                                </DropdownMenu.Content>
                            </DropdownMenu.Root>
                        {/if}
                    </div>
                </Table.Row>
            {/each}
        </Table.Body>
    </Table.Root>
{:else}
    <div class="px-4 py-3">
        {#if data.activeSubsTab === 'subscriptions'}
            <p>You have no active or cancelled subscriptions at the moment.</p>
        {:else}
            <p>You have no active subscribers at the moment.</p>
        {/if}
    </div>
{/if}
<AlertDialog.Root bind:open="{isDialogOpen}">
    <AlertDialog.Content>
        <AlertDialog.Header>
            <AlertDialog.Title>Confirm Subscription Cancellation</AlertDialog.Title>
            <AlertDialog.Description>
                Are you sure you want to cancel this subscription? This action cannot be undone.
            </AlertDialog.Description>
        </AlertDialog.Header>
        {#if cancellationError}
            <div class="mt-2 text-red-500">{cancellationError}</div>
        {/if}
        <AlertDialog.Footer>
            <AlertDialog.Cancel on:click="{() => (isDialogOpen = false)}">No, keep subscription</AlertDialog.Cancel>
            <AlertDialog.Action on:click="{cancelSubscription}" disabled="{isCancelling}">
                {#if isCancelling}
                    Cancelling...
                {:else}
                    Yes, cancel subscription
                {/if}
            </AlertDialog.Action>
        </AlertDialog.Footer>
    </AlertDialog.Content>
</AlertDialog.Root>
