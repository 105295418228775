<script>
    import toast from 'svelte-french-toast';
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import axios from 'axios';

    // props from marketplace controller
    export let authUser;
    export let staticReferralMarketUsers;

    function purchaseUser(offeringId, userId, price) {
        if (confirm(`Are you sure you want to purchase this post for ${price}?`)) {
            document.querySelector(`#spinner_${offeringId}`).style.display = 'inline-block';
            const placeBidButton = document.querySelector(`#button_${offeringId}`);
            placeBidButton.querySelector('.buttonText').style.display = 'none';

            let values = {
                user_id: userId,
                offering_id: offeringId,
            };

            axios
                .post('/purchase-referral-market-user', values)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success('Congrats! user has been purchased');

                        // remove the div from the frontend
                        const divToRemove = document.getElementById(`wrapped-static-div-${offeringId}`);
                        divToRemove.innerHTML = '';

                        placeBidButton.disabled = false;
                        placeBidButton.querySelector('.spinner').style.display = 'none';
                        placeBidButton.querySelector('.buttonText').style.display = 'inline-block';
                    } else {
                        const errorMessage = response.data.message || 'An error occurred.';
                        toast.error(errorMessage);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);

                    placeBidButton.disabled = false;
                    placeBidButton.querySelector('.spinner').style.display = 'none';
                    placeBidButton.querySelector('.buttonText').style.display = 'inline-block';
                });
        }
    }
</script>

<svelte:component this="{MarketplaceNav}" />

<div class="col-12 min-vh-100 pr-0 pt-4">
    {#if staticReferralMarketUsers.length > 0}
        {#each staticReferralMarketUsers as user}
            {#if user.id != authUser}
                <div class="mb-4" id="{'wrapped-static-div-' + user.id}">
                    <div class="mx-auto max-w-xs">
                        <div class="flex h-full flex-col overflow-hidden rounded-lg bg-white shadow-lg">
                            <!-- Image -->
                            <a
                                class="block focus:outline-none focus-visible:ring-2"
                                href="{window.location.host + '/' + user.user.username}"
                            >
                                <figure class="relative h-0 overflow-hidden pb-[56.25%]">
                                    <img
                                        class="absolute inset-0 h-full w-full transform object-cover transition duration-700 ease-out hover:scale-105"
                                        src="{user.user.avatar}"
                                        width="320"
                                        height="180"
                                        alt="Course"
                                    />
                                </figure>
                            </a>
                            <!-- Card Content -->
                            <div class="flex flex-grow flex-col p-5">
                                <!-- Card body -->
                                <div class="flex-grow">
                                    <!-- Header -->
                                    <header class="mb-3">
                                        <a
                                            class="block focus:outline-none focus-visible:ring-2"
                                            href="{window.location.host + '/' + user.user.username}"
                                        >
                                            <h3 class="text-[22px] font-extrabold leading-snug text-gray-900">
                                                {user.user.name}
                                            </h3>
                                        </a>
                                        <h6><b> Price: {user.listing.price}</b></h6>
                                    </header>
                                    <!-- Content -->
                                    <div class="mb-8">
                                        <p>Followers : {user.user.follower_amount}</p>
                                        <br />
                                    </div>
                                </div>

                                <div class="flex justify-end space-x-2">
                                    <a
                                        class="inline-flex items-center justify-center rounded border border-transparent bg-indigo-50 px-3 py-1.5 text-sm font-semibold leading-5 text-indigo-500 shadow-sm transition duration-150 ease-in-out hover:bg-indigo-100 focus:outline-none focus-visible:ring-2"
                                        href="{window.location.host + '/' + user.user.username}"
                                        >view profile
                                    </a>

                                    <form class="purchase-form" id="{'form-' + user.id}">
                                        <button
                                            on:click="{() => purchaseUser(user.id, user.user_id, user.listing.price)}"
                                            id="{'button_' + user.id}"
                                            class="inline-flex items-center justify-center rounded border border-transparent bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-5 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-600 focus:outline-none focus-visible:ring-2"
                                            type="button"
                                        >
                                            <span id="{'spinner_' + user.id}" class="spinner" style="display: none">
                                                <!-- @include('elements.icon',['icon'=>'refresh','variant'=>'small']) -->

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                    height="24"
                                                >
                                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                                    <path
                                                        d="M15 4v7h5V4h-5zM4 15h5v5H4v-5zM4 4v5h5V4H4zm11 11h5v5h-5v-5z"
                                                        fill="currentColor"
                                                    ></path>
                                                    <path d="M8 8v8h8V8H8zm7 7H9V9h6v6z" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <span class="buttonText">Buy Now</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/if}
        {/each}
    {:else}
        <div class="flex flex-col ml-4 gap-y-2">
            <span class="text-lg font-semibold">Currently there are no profiles listed for sale.</span>
            <span>Come back later!</span>
        </div>
    {/if}
</div>
