<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import toast from 'svelte-french-toast';
    import { Switch } from '$lib/components/ui/switch';
    import { Label } from '$lib/components/ui/label';
    import axios from 'axios';
    import type { SettingsContext } from '@/interfaces/settings';

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser, data } = settingsContext;

    interface NotificationSettings {
        [key: string]: boolean;
    }

    let notificationSettings: NotificationSettings = {
        notification_email_new_post_created: false,
        notification_email_new_sub: false,
        notification_email_new_tip: false,
        notification_email_new_ppv_unlock: false,
        notification_email_new_message: false,
        notification_email_new_comment: false,
        notification_email_expiring_subs: false,
        notification_email_renewals: false,
        notification_email_creator_went_live: false,
    };

    onMount(() => {
        // Initialize settings from authUser
        for (const key in notificationSettings) {
            notificationSettings[key] = authUser.settings[key] === 'true';
        }
    });

    async function updateUserSetting(key: string, value: boolean) {
        try {
            const response = await axios.post('/my/settings/save', {
                key,
                value: value.toString(), // Send 'true' or 'false' as a string
            });

            if (response.data.success) {
                toast.success('Setting saved');
                // Update the authUser object to reflect the new state
                authUser.settings[key] = value.toString();
            } else {
                throw new Error('Setting save failed');
            }
        } catch (error) {
            console.error('Error saving setting:', error);
            toast.error('Setting save failed');
            // Revert the change in the UI
            notificationSettings[key] = !value;
        }
    }

    function handleToggle(key: string) {
        return async () => {
            const newValue = !notificationSettings[key];
            notificationSettings[key] = newValue;
            await updateUserSetting(key, newValue);
        };
    }
</script>

<form class="space-y-4">
    {#if data.enable_new_post_notification_setting}
        <div class="flex items-center space-x-2">
            <Switch
                id="notification_email_new_post_created"
                checked="{notificationSettings.notification_email_new_post_created}"
                onCheckedChange="{handleToggle('notification_email_new_post_created')}"
            />
                <Label for="notification_email_new_post_created" class="m-0 ml-2">New content has been posted</Label>
        </div>
    {/if}

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_new_sub"
            checked="{notificationSettings.notification_email_new_sub}"
            onCheckedChange="{handleToggle('notification_email_new_sub')}"
        />
        <Label for="notification_email_new_sub" class="m-0 ml-2">New subscription registered</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_new_tip"
            checked="{notificationSettings.notification_email_new_tip}"
            onCheckedChange="{handleToggle('notification_email_new_tip')}"
        />
        <Label for="notification_email_new_tip" class="m-0 ml-2">Received a tip</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_new_ppv_unlock"
            checked="{notificationSettings.notification_email_new_ppv_unlock}"
            onCheckedChange="{handleToggle('notification_email_new_ppv_unlock')}"
        />
            <Label for="notification_email_new_ppv_unlock" class="m-0 ml-2">Your PPV content has been unlocked</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_new_message"
            checked="{notificationSettings.notification_email_new_message}"
            onCheckedChange="{handleToggle('notification_email_new_message')}"
        />
            <Label for="notification_email_new_message" class="m-0 ml-2">New message received</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_new_comment"
            checked="{notificationSettings.notification_email_new_comment}"
            onCheckedChange="{handleToggle('notification_email_new_comment')}"
        />
            <Label for="notification_email_new_comment" class="m-0 ml-2">New comment received</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_expiring_subs"
            checked="{notificationSettings.notification_email_expiring_subs}"
            onCheckedChange="{handleToggle('notification_email_expiring_subs')}"
        />
        <Label for="notification_email_expiring_subs" class="m-0 ml-2">Expiring subscriptions</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_renewals"
            checked="{notificationSettings.notification_email_renewals}"
            onCheckedChange="{handleToggle('notification_email_renewals')}"
        />
            <Label for="notification_email_renewals" class="m-0 ml-2">Upcoming renewals</Label>
    </div>

    <div class="flex items-center space-x-2">
        <Switch
            id="notification_email_creator_went_live"
            checked="{notificationSettings.notification_email_creator_went_live}"
            onCheckedChange="{handleToggle('notification_email_creator_went_live')}"
        />
            <Label for="notification_email_creator_went_live" class="m-0 ml-2">A user I am following went live</Label>
    </div>
</form>
