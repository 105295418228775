<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import toast from 'svelte-french-toast';
    import { Card, CardContent } from '$lib/components/ui/card';
    import { Switch } from '$lib/components/ui/switch';
    import { Label } from '$lib/components/ui/label';
    import * as Select from '$lib/components/ui/select';
    import axios from 'axios';
    import { Button } from '$lib/components/ui/button';
    import { tick } from 'svelte';
    import type { User } from '@/interfaces/general';
    import type { SettingsContext } from '@/interfaces/settings';

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser, data } = settingsContext;

    let selectedCountries = authUser.settings.geoblocked_countries || [];

    async function updateFlagSetting(key: 'public_profile' | 'open_profile' | 'enable_geoblocking', value: boolean) {
        try {
            await axios.post('/my/settings/flags/save', { key, value });
            authUser[key] = value;
            toast.success('Setting updated successfully');
        } catch (error) {
            toast.error('Failed to update setting');
        }
    }

    async function updateGeoBlockedCountries() {
        try {
            await tick();
            await axios.post('/my/settings/save', { key: 'geoblocked_countries', value: selectedCountries });
            authUser.settings.geoblocked_countries = selectedCountries;
            toast.success('Geo-blocked countries updated');
        } catch (error) {
            toast.error('Failed to update geo-blocked countries');
        }
    }

    let deviceToBeDeleted: string | null = null;
    let showDeleteDialog = false;

    function showDeviceDeleteConfirmation(signature: string) {
        deviceToBeDeleted = signature;
        showDeleteDialog = true;
    }

    async function removeDevice() {
        if (!deviceToBeDeleted) return;

        try {
            const response = await axios.delete('/device-verify/delete', {
                data: { signature: deviceToBeDeleted },
            });
            if (response.data.success) {
                toast.success('Device removed successfully');
                // Implement reload functionality or update the device list
            } else {
                toast.error('Failed to remove device');
            }
        } catch (error) {
            toast.error('Failed to remove device');
        }
        showDeleteDialog = false;
        deviceToBeDeleted = null;
    }
</script>

<div class="space-y-4">
    <Card>
        <CardContent class="pt-6">
            <div class="flex items-center gap-x-2">
                <Switch
                    id="public_profile"
                    checked="{authUser.public_profile}"
                    on:click="{() => updateFlagSetting('public_profile', !authUser.public_profile)}"
                />
                <span >Public Profile</span>
            </div>
            <div class="mt-4 text-sm">
                <span>Not having your profile set to public means:</span>
                <ul class="mt-1 list-disc space-y-1 pl-5">
                    <li>It will be hidden for search engines and unregistered users entirely.</li>
                    <li>It will also be generally hidden from suggestions and user searches on our platform.</li>
                </ul>
            </div>
        </CardContent>
    </Card>

    {#if data.allow_users_enabling_open_profiles}
        <Card>
            <CardContent class="pt-6">
                <div class="flex items-center space-x-2">
                    <Switch
                        id="open_profile"
                        checked="{authUser.open_profile}"
                        on:click="{() => updateFlagSetting('open_profile', !authUser.open_profile)}"
                    />
                    <Label for="open_profile">Open profile</Label>
                </div>
                <div class="mt-2">
                    <span>Having your profile set to open means:</span>
                    <ul class="mt-1 list-disc space-y-1 pl-5">
                        <li>Both registered and unregistered users will be able to see your posts.</li>
                        <li>If account is private, the content will only be available for registered used.</li>
                        <li>Paid posts will still be locked for open profiles.</li>
                    </ul>
                </div>
            </CardContent>
        </Card>
    {/if}
    {#if data.allow_geo_blocking}
        <Card>
            <CardContent class="pt-6">
                <div class="flex items-center space-x-2">
                    <Switch
                        id="enable_geoblocking"
                        checked="{authUser.enable_geoblocking}"
                        on:click="{() => updateFlagSetting('enable_geoblocking', !authUser.enable_geoblocking)}"
                    />
                    <Label for="enable_geoblocking">Enable Geo-blocking</Label>
                </div>
                <div class="mt-2">
                    <small class="text-sm text-gray-500">
                        If enabled, visitors from certain countries will be restricted access.
                    </small>
                </div>
                <div class="mt-4">
                    <Label for="countrySelect">Country</Label>
                    <Select.Root
                        disabled="{!authUser.enable_geoblocking}"
                        multiple
                        bind:selected="{selectedCountries}"
                        onSelectedChange="{updateGeoBlockedCountries}"
                    >
                        <Select.Trigger class="w-full">
                            <Select.Value placeholder="Select countries" />
                        </Select.Trigger>
                        <Select.Content>
                            <div class="max-h-48 overflow-y-auto">
                                {#each data.countries as country}
                                    <Select.Item label="{country.name}" value="{country.name}"
                                        >{country.name}</Select.Item
                                    >
                                {/each}
                            </div>
                        </Select.Content>
                    </Select.Root>
                </div>
            </CardContent>
        </Card>
    {/if}
</div>

{#if showDeleteDialog}
    <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="rounded-lg bg-white p-6">
            <h2 class="mb-4 text-lg font-bold">Confirm Device Deletion</h2>
            <p>Are you sure you want to delete this device?</p>
            <div class="mt-4 flex justify-end space-x-2">
                <Button on:click="{() => (showDeleteDialog = false)}">Cancel</Button>
                <Button variant="destructive" on:click="{removeDevice}">Delete</Button>
            </div>
        </div>
    </div>
{/if}
