<script lang="ts">
    import { Input } from '$lib/components/ui/input/index.js';
    import { Label } from '$lib/components/ui/label/index.js';
    import { Button } from '$lib/components/ui/button';
    import { Separator } from '$lib/components/ui/separator/index.js';
    import { inertia, router } from '@inertiajs/svelte';
    import axios from 'axios';
    import { Turnstile } from 'svelte-turnstile';
    import { onMount } from 'svelte';
    import { getTheme } from '@/utility/theming/darkmode.ts';
    import toast from 'svelte-french-toast';
    import { createStarGrid, animateStars } from '@/utility/stars';
    import AuthPageFormContainer from './AuthPageFormContainer.svelte';

    const SITE_KEY = '0x4AAAAAAAIbV5smWueSWBbM';

    let turnstileToken = '';

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');

    if (csrfTokenMeta) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfTokenMeta.getAttribute('content');
    }

    onMount(() => {
        const starsContainer: HTMLElement = document.getElementById('stars-container') as HTMLElement;
        createStarGrid(starsContainer);
        setInterval(() => animateStars(), 100);
    });

    let validatingUserCredentials = false;
    let uerCredentials = {
        email: '',
        password: '',
        remember: true,
    };

    function handleTurnstileCallback(event: CustomEvent<{ token: string }>): void {
        turnstileToken = event.detail.token;
    }

    function resetTurnstileToken() {
        turnstileToken = '';
    }

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        validatingUserCredentials = true;

        const loginData = {
            ...uerCredentials,
            turnstileToken,
        };

        axios
            .post('/login-post', loginData)
            .then((response) => {
                if (response.status === 200) {
                    toast.success('Login Success');
                    window.location.href = '/feed';
                } else {
                    const errorMessage = response.data || 'An error occurred.';
                    toast.error(errorMessage);
                }
            })
            .catch((error) => {
                console.log(error);
                const errorMessage = error.response?.data || 'An error occurred.';
                toast.error(errorMessage);
            })
            .finally(() => {
                validatingUserCredentials = false;
            });
    };
</script>

<AuthPageFormContainer>
    <form on:submit="{handleSubmit}">
        <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
            <Label for="email" class="dark:text-white">Email</Label>
            <Input
                class="dark:text-white"
                type="email"
                name="email"
                bind:value="{uerCredentials.email}"
                id="email"
                placeholder="Enter Email"
            />
        </div>
        <div class="mb-1 flex w-full max-w-lg flex-col gap-1.5">
            <Label for="password" class="dark:text-white">Password</Label>
            <Input
                class="dark:text-white"
                type="password"
                name="password"
                id="password"
                bind:value="{uerCredentials.password}"
                placeholder="Enter Password"
            />
        </div>
        <div class="mb-1 flex w-full justify-end text-sm">
            <a
                use:inertia
                href="/password/reset"
                class="text-gradient font-weight-bold bg-gradient-to-r from-bmn-400 to-bmn-500 hover:!from-bmn-400 hover:!to-bmn-700"
            >
                Forgot Your Password?
            </a>
        </div>

        <Turnstile
            siteKey="{SITE_KEY}"
            theme="{getTheme()}"
            on:turnstile-callback="{handleTurnstileCallback}"
            on:turnstile-expired="{resetTurnstileToken}"
            on:turnstile-timeout="{resetTurnstileToken}"
            on:turnstile-error="{resetTurnstileToken}"
            class="mb-3"
        />
        <Button
            class="w-full text-white"
            type="submit"
            disabled="{validatingUserCredentials ||
                !uerCredentials.email ||
                !uerCredentials.password ||
                !turnstileToken}"
        >
            {validatingUserCredentials ? 'Loading...' : window.location.href === '/feed' ? 'Done' : 'Login'}
        </Button>

        <Separator class="mb-3 mt-4 bg-neutral-200 dark:bg-neutral-800" />

        <p class="mb-0 text-center text-sm text-muted-foreground dark:text-white">
            Don't have an account?
            <a
                use:inertia
                href="/register"
                class=" text-gradient font-weight-bold bg-gradient-to-r from-bmn-400 to-bmn-500 hover:!from-bmn-400 hover:!to-bmn-700"
            >
                Sign up
            </a>
        </p>
    </form>
</AuthPageFormContainer>
