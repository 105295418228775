<script lang="ts">
    import { Input } from '$lib/components/ui/input';
    import { Label } from '$lib/components/ui/label';
    import { Button } from '$lib/components/ui/button';
    import { Separator } from '$lib/components/ui/separator';
    import { inertia, router } from '@inertiajs/svelte';
    import axios from 'axios';
    import { Turnstile } from 'svelte-turnstile';
    import { onMount } from 'svelte';
    import { getTheme } from '@/utility/theming/darkmode.ts';
    import { Checkbox } from '$lib/components/ui/checkbox';
    import toast from 'svelte-french-toast';
    import { animateStars, createStarGrid } from '@/utility/stars';
    import AuthPageFormContainer from './AuthPageFormContainer.svelte';

    const SITE_KEY = '0x4AAAAAAAIbV5smWueSWBbM';

    let turnstileToken = '';

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');

    if (csrfTokenMeta) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfTokenMeta.getAttribute('content');
    }

    let creatingUser = false;

    $: creatingUser;

    let userData = {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        terms: false,
    };

    function handleTurnstileCallback(event: CustomEvent<{ token: string }>): void {
        turnstileToken = event.detail.token;
    }

    function resetTurnstileToken() {
        turnstileToken = '';
    }

    onMount(() => {
        const starsContainer: HTMLElement = document.getElementById('stars-container') as HTMLElement;
        createStarGrid(starsContainer);
        setInterval(() => animateStars(), 100);
    });

    const handleSubmit = (event: Event) => {
        event.preventDefault();
        creatingUser = true;

        if (!userData.password || !userData.password_confirmation) {
            toast.error('Both password fields are required');
        } else if (userData.password.trim() !== userData.password_confirmation.trim()) {
            toast.error('Passwords do not match');
        } else if (!userData.terms) {
            toast.error('Please agree to our Terms and conditions');
        } else if (!turnstileToken) {
            toast.error('Please complete the Turnstile verification');
        } else {
            const registrationData = {
                ...userData,
                turnstileToken,
            };

            axios
                .post('/register-post', registrationData)
                .then((response) => {
                    if (response.status === 200) {
                        toast.success('Registration Success');
                        window.location.href = '/feed';
                    } else {
                        const errorMessage = response.data.message || 'An error occurred.';
                        toast.error(errorMessage);
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response?.data.message || 'An error occurred.';
                    toast.error(errorMessage);
                })
                .finally(() => {
                    creatingUser = false;
                });
        }
        creatingUser = false;
    };

</script>

<AuthPageFormContainer>
    <form on:submit="{handleSubmit}">
        <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
            <Label for="name" class="dark:text-white">Name</Label>
            <Input
                type="text"
                id="name"
                name="name"
                bind:value="{userData.name}"
                required
                placeholder="Enter Name"
                class="dark:text-white"
            />
        </div>
        <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
            <Label for="email" class="dark:text-white">Email Address</Label>
            <Input
                type="email"
                id="email"
                name="email"
                bind:value="{userData.email}"
                placeholder="Enter Email Address"
                class="dark:text-white"
            />
        </div>
        <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
            <Label for="password" class="dark:text-white">Password</Label>
            <Input
                type="password"
                name="password"
                bind:value="{userData.password}"
                required
                id="password"
                placeholder="Enter Password"
                class="dark:text-white"
            />
        </div>
        <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
            <Label for="cpassword" class="dark:text-white">Confirm Password</Label>
            <Input
                type="password"
                name="password_confirmation"
                bind:value="{userData.password_confirmation}"
                required
                id="cpassword"
                placeholder="Enter Confirm Password"
                class="dark:text-white"
            />
        </div>
        <div class="mb-3 flex items-center space-x-2">
            <Checkbox bind:checked="{userData.terms}" />
            <Label
                id="terms-label"
                for="terms"
                class="my-0 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:text-white"
            >
                I agree to the <a use:inertia href="/terms-and-conditions" class="underline">Terms and Conditions</a>
                and <a use:inertia href="/privacy-policy" class="underline">Privacy Policy</a>
            </Label>
        </div>
        <Turnstile
            siteKey="{SITE_KEY}"
            size="flexible"
            theme="{getTheme()}"
            on:turnstile-callback="{handleTurnstileCallback}"
            on:turnstile-expired="{resetTurnstileToken}"
            on:turnstile-timeout="{resetTurnstileToken}"
            on:turnstile-error="{resetTurnstileToken}"
            class="mb-3"
        />
        <Button
            class="mb-3 w-full text-white"
            type="submit"
            disabled="{creatingUser ||
                !userData.name ||
                !userData.email ||
                !userData.password ||
                !userData.password_confirmation ||
                !userData.terms ||
                !turnstileToken}"
        >
            {creatingUser ? 'Registering...' : 'Register'}
        </Button>
        <Separator class="my-3 bg-neutral-200 dark:bg-neutral-800" />
        <p class="text-center text-sm text-muted-foreground dark:text-white">
            Already have an account?
            <a
                use:inertia
                href="/login"
                class=" text-gradient font-weight-bold bg-gradient-to-r from-bmn-400 to-bmn-500 hover:!from-bmn-400 hover:!to-bmn-700"
            >
                Login
            </a>
        </p>
    </form>
</AuthPageFormContainer>
