<main class="loginScreen d-flex w-100 h-screen items-center justify-center">
    <div id="stars-container" class="absolute h-full"></div>
    <div class="authFormContainer">
        <div class="min-w-[300px]">
            <a href="/" class="d-block text-center">
                <img class="mx-auto mb-3 w-[150px]" alt="BMN Logo" src="/img/logo-white.png" />
            </a>
            <slot></slot>
        </div>
    </div>
</main>
